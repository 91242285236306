<template>
  <div>
    <el-card>
      <div class="member">
        会员账号
        <el-input
          v-model="queryInfo.mobile"
          placeholder="请输入会员账号"
          clearable
          @clear="handleChange"
          @change="handleChange"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleChange"
          ></el-button>
        </el-input>
      </div>
      <!-- <div class="select">
        会员等级
        <el-select
          v-model="queryInfo.memberGrade"
          placeholder="全部"
          clearable
          @clear="getMemberSalesList"
          @change="handleChange"
        >
          <el-option
            v-for="item in forPeopleOpt"
            :key="item.id"
            :label="item.gradeName"
            :value="item.gradeName"
          ></el-option>
        </el-select>
      </div> -->
      <el-table
        :data="memberExpenseList"
        border
        stripe
      >
        <!-- <el-table-column
          label="id"
          prop="memberId"
        ></el-table-column> -->
        <el-table-column
          label="会员账号"
          align="center"
          prop="mobile"
        ></el-table-column>
        <el-table-column
          label="会员昵称"
          align="center"
          prop="userName"
        ></el-table-column>
        <!-- <el-table-column
          label="会员等级"
          align="center"
          prop="memberGrade"
        ></el-table-column> -->
        <el-table-column
          width="250"
          align="center"
          label=""
          prop="orderConsumeTotal"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <span @click="handleEdit(scope.$index)">总消费金额（元）</span>
            <i
              class="el-icon-caret-bottom"
              @click="handleEdit(scope.$index)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          width="250"
          align="center"
          label=""
          prop="orderNumberTotal"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <span @click="handleEdit(scope.$index)">总消费订单数</span>
            <i
              class="el-icon-caret-bottom"
              @click="handleEdit(scope.$index)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          width="250"
          align="center"
          label=""
          prop="guestUnitPrice"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <span @click="handleEdit(scope.$index)">客单价（元）</span>
            <i
              class="el-icon-caret-bottom"
              @click="handleEdit(scope.$index)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1,
        memberGrade: '', // 等级
        mobile: '',
        sortType: 0
      },
      memberExpenseList: [],
      forPeopleOpt: [],
      total: 0
    }
  },
  created () {
    this.getMemberSalesList()
    this.getMemberLevel()
  },
  methods: {
    handleChange () {
      this.queryInfo.pageNo = 1
      this.getMemberSalesList()
    },
    async getMemberSalesList () {
      const { data: res } = await this.$http.get('userManager/consume', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.memberExpenseList = res.data.data.map(item => ({
        ...item,
        orderConsumeTotal: (item.orderConsumeTotal / 100.0).toFixed(2),
        guestUnitPrice: (item.guestUnitPrice / 100.0).toFixed(2)
      }))
      this.total = res.data.total
    },
    // 会员等级
    async getMemberLevel () {
      const { data: res } = await this.$http.get('/memberGrade/list')
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.forPeopleOpt = res.data.data
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getMemberSalesList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getMemberSalesList()
    },
    handleEdit (index) {
      console.log(index)
      if (index === 2) {
        this.queryInfo.sortType = 0
        this.getMemberSalesList()
      } else if (index === 3) {
        this.queryInfo.sortType = 1
        this.getMemberSalesList()
      } else if (index === 4) {
        this.queryInfo.sortType = 2
        this.getMemberSalesList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.member {
  width: 350px;
  .el-input {
    width: 250px;
  }
}
.select {
  width: 300px;
  margin-left: 350px;
  margin-top: -40px;
  .el-select {
    width: 160px;
  }
}
</style>
